import logo from './logo.png';
import './App.css';
import { FaInstagram } from 'react-icons/fa'
import { MdOutlineAlternateEmail } from 'react-icons/md'

function App() {
  return (
    <div className="App" style={{ 
      backgroundImage: `url("./img/under-construction-splash.jpeg")`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: 'no-repeat',
      height: '100vh',
      width: '100vw'
    }}>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Our website is under construction.<br/>We'll be here soon!
        </p>
        <div className="Socials" style={{
          display: 'flex'
        }}>
          <div>
            <a className="SocialsLink" href="https://www.instagram.com/megastonewoodinc"><FaInstagram/></a>
          </div>
          <div>
            <a className="SocialsLink" href="mailto:soystone10@yahoo.com"><MdOutlineAlternateEmail/></a>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
